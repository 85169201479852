import { LeaderBoardSkeleton, LeaderBoardItem } from "./SkeletonLoader.styled";

function LeaderBoardLoader() {
    return (
      <LeaderBoardSkeleton>
        <LeaderBoardItem header />
        <LeaderBoardItem />
        <LeaderBoardItem />
        <LeaderBoardItem />
        <LeaderBoardItem />
        <LeaderBoardItem />
      </LeaderBoardSkeleton>
    );
}

export default LeaderBoardLoader;