import { ChallengeSkeleton, Line, Circle } from "./SkeletonLoader.styled";

function ChallengeLoader() {
  return (
    <ChallengeSkeleton>
      <Line height="21" width="129" absolute top="20px" left="30px" />
      <Line height="21" width="107" absolute top="48px" left="30px" />
      <Line height="26" width="93" absolute top="18px" right="23px" />
      <Line height="21" width="81" absolute top="89px" left="30px" />
      <Line height="28" width="87" absolute bottom="37px" left="30px" />
      <Circle height="40" width="40" absolute bottom="33px" right="26px" />
    </ChallengeSkeleton>
  );
}

export default ChallengeLoader;
