import styled from "styled-components";
import { devices } from "../../constants/devices";
export const SubscribeContainer = styled.div`
  width: 292px;
  background: #f8f8f8;
  border-radius: 9px;
  opacity: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  position: relative;
  padding-bottom: 20px;

  @media ${devices.tablet} {
    width: 400px;
    height: 174px;
    padding-bottom: 0;
  }
`;

export const Title = styled.div`
  font-family: poppins300;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0px;
  color: #6a36f0;
  margin-top: 35px;
`;

export const Description = styled.div`
  color: #3d3d3d;
  font-family: poppins300;
  font-size: 12px;
  line-height: 18px;
  margin-top: 4px;
`;

export const InputContainer = styled.form`
  width: 319px;

  border-radius: 5px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 5px;
  flex-direction: column;
  @media ${devices.tablet} {
    height: 35px;
    background: #ffffff;
    gap: 0;
    flex-direction: row;
  }
`;

export const MailInput = styled.input`
  width: calc(100% - 141px);
  border: none;
  outline: none;
  font-family: poppins-normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  color: #4e4e4e;
  height: 35px;
  text-align: center;
  :focus {
    border: none;
    outline: none;
  }

  @media ${devices.tablet} {
    margin-left: 15px;
    text-align: left;
    height: fit-content;
  }
`;

export const NotifyButton = styled.input`
  cursor: pointer;
  width: 141px;
  height: 35px;
  background: #6a36f0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: poppins-normal;
  font-size: 14px;
  line-height: 21px;
  color: white;
  border: none;

  :focus{
    border: none;
    outline: none;
  }
`;

export const AlertBox = styled.div`
  display: ${(props) => (props.visibility ? "block" : "none")};
  position: absolute;
  top: 88px;
  text-align: center;
  width: 100%;
  height: 17px;
  font-family: poppins300;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => (props.success ? "#6a36f0" : "red")};

  @media ${devices.tablet} {
    top: unset;
    bottom: 12px;

  }
`;

