import {
  BigOrderSkeleton,
  BigOrderItemSkeleton,
  Square,
  BigOrderItemData,
  Line,
} from "./SkeletonLoader.styled";

function BigOrderLoader() {
  return (
    <BigOrderSkeleton>
      <BigOrderItemSkeleton>
        <Square />
        <BigOrderItemData>
          <Line height="21" width="150" />
          <Line height="16" width="83" />
        </BigOrderItemData>
      </BigOrderItemSkeleton>
      <BigOrderItemSkeleton>
        <Square />
        <BigOrderItemData>
          <Line height="21" width="150" />
          <Line height="16" width="83" />
        </BigOrderItemData>
      </BigOrderItemSkeleton>
      <BigOrderItemSkeleton>
        <Square />
        <BigOrderItemData>
          <Line height="21" width="150" />
          <Line height="16" width="83" />
        </BigOrderItemData>
      </BigOrderItemSkeleton>
    </BigOrderSkeleton>
  );
}

export default BigOrderLoader;
