import styled from "styled-components";
import { devices } from "../../constants/devices";

export const LeaderboardMainContainer = styled.div`
  width: 418px;
  height: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${devices.tablet} {
    display: block;
  }
`;
export const BriyaniVoucher = styled.div`
  width: 292px;
  height: 101px;
  background: #f7f4ff 0% 0% no-repeat padding-box;
  border: 1px solid #3d3d3d;
  border-radius: 11px;
  letter-spacing: 0px;
  color: #3d3d3d;
  font-family: poppins-normal;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 17px;
  @media ${devices.tablet} {
    width: 400px;
  }
`;

export const MainRating = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  border: 1px solid #707070;
  border-radius: 11px;
  width: 292px;
  height: 133px;
  @media ${devices.tablet} {
    width: 400px;
  }
`;
export const StarRatingContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgba(247, 244, 255, 0.7) 0% 0% no-repeat padding-box;
  height: 60px;
  border-radius: 11px;
  cursor: pointer;
  #starDiv {
    display: flex;
    gap: 5px;
  }
  img {
    filter: grayscale(100%);
    transition: filter 0.0s ease;
  }
  img:hover,
  img.star-yellow {
    filter: none;
  }
  #pleaseRate {
    font-family: poppins-normal;
  }
`;
export const RatingContainer = styled.div`
  display: flex;
  height: 51px;
  justify-content: center;
  align-items: baseline;
  gap: 6px;
  #rating {
    width: 54px;
    height: 51px;
    font-size: 36px;
    font-family: poppins-normal;
    letter-spacing: 0px;
    color: #3d3d3d;
    margin-top: 12px;
  }
`;
// export const InstructionContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-top: 14px;
//   gap: 24px;

//   #ins-content-1 {
//     width: fit-content;
//     height: 58px;
//     left: 0px;
//     @media ${devices.tablet} {
//       width: 355px;
//       height: 48px;
//     }
//   }
//   #ins-content-2 {
//     width: fit-content;
//     height: 58px;
//     /* left: 10px; */

//     @media ${devices.tablet} {
//       width: 301px;
//       height: 48px;
//       left: 50px;
//     }
//   }
// `;

export const Instruction = styled.div`
  width: 292px;
  height: 101px;
  background: #f7f4ff;
  border: 1px solid #6a36f0;
  border-radius: 11px;
  position: relative;
  @media ${devices.tablet} {
    width: 400px;
  }
`;

export const InstructionHeader = styled.div`
  width: 174px;
  height: 28px;
  background: #6a36f0;
  border-radius: 14px;
  text-align: center;
  font-family: poppins300;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0px;
  color: #ffffff;
  position: absolute;
  left: 65px;
  bottom: 87px;
  @media ${devices.tablet} {
    left: 114px;
  }
`;

export const InstructionContent = styled.div`
  text-align: center;
  font-family: poppins300;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0px;
  color: #6a36f0;
  position: absolute;
  bottom: 23px;
`;

export const LeaderboardContainer = styled.div`
  width: 292px;
  height: auto;
  background: #f7f4ff;
  border-radius: 11px;
  margin: auto;
  margin-top: 14px;
  padding: 15px 0 10px 40px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(106, 54, 240);
    border-radius: 4px;
    margin-right: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(106, 54, 240);
    margin-right: 10px;
  }
  @media ${devices.tablet} {
    width: 400px;
    overflow-x: unset;
    padding: 20px 0;
  }
`;

export const LeaderboardTitle = styled.div`
  text-align: center;
  font-family: poppins-normal;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #3d3d3d;
  width: 100%;
  span {
    color: #3d3d3d;
    font-weight: 500;
  }
`;

export const Row = styled.div`
  width: ${(props) => (props.heading || props.userRow ? "330px" : "310px")};
  height: ${(props) =>
    props.heading ? "30px" : props.userRow ? "38px" : "28px"};
  background: ${(props) =>
    props.heading ? "#707070 0% 0% no-repeat padding-box" : "#ffffff"};
  border: ${(props) => (props.heading ? "none" : "0.5px solid #6A36F080")};
  margin-top: ${(props) =>
    props.heading ? "11px" : props.userRow ? "4px" : "0"};
  margin-bottom: ${(props) =>
    props.heading ? "12px" : props.userRow ? "8px" : "4px"};
  display: flex;
  @media ${devices.tablet} {
    width: ${(props) => (props.heading || props.userRow ? "418px" : "380px")};
  }
  .cell-1 {
    margin-left: ${(props) =>
      props.heading || props.userRow ? "24px" : "18px"};
    width: 39px;
    font-size: 12px;

    @media ${devices.tablet} {
      font-size: 16px;
      margin-left: ${(props) =>
        props.heading || props.userRow ? "37px" : "18px"};
    }
  }

  .cell-2 {
    margin-left: 20px;
    width: 174px;
    font-size: 12px;
    @media ${devices.tablet} {
      font-size: 16px;
    }
  }

  .cell-3 {
    font-size: 12px;
    @media ${devices.tablet} {
      font-size: 16px;
      margin-left: 20px;
    }
  }
`;

export const Cell = styled.div`
  font-family: poppins300;
  letter-spacing: 0px;
  color: ${(props) =>
    props.heading ? "#ffffff" : props.userRow ? "#6A36F0" : "#3D3D3D"};
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.topper ? "space-between" : "unset")};
  /* margin-left: unset !important; */
  /* margin-left: 0px !important; */
  font-size: 12px;
  img {
    width: 16px;
    height: 16px;
  }
  @media ${devices.tablet} {
    font-family: poppins300;
    font-size: 16px;
    line-height: 25px;
  }
`;
