import { createGlobalStyle } from "styled-components";
import poppins300 from "../fonts/Poppins-Light.ttf";
import poppins400 from "../fonts/Poppins-Medium.ttf";
import poppinsItalic400 from "../fonts/Poppins-MediumItalic.ttf";
import robotoBold from "../fonts/Roboto-Bold.ttf";
export const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: poppins300;
  src: url(${poppins300});
}
@font-face {
  font-family: poppins-normal;
  src: url(${poppins400});
}
@font-face {
  font-family: poppins-normal-italic;
  src: url(${poppinsItalic400});
}
@font-face {
  font-family: roboto-bold;
  src: url(${robotoBold});
}

.flex-row-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
    body{
        margin: 0;
    }


`;
