
import { LeftContainerWrapper, FoodTrayImg } from "./LeftContainer.styled"
import FoodTrayIcon from '../../pictures/foodTray.svg'

const LeftContainer=()=>{
    return(

        <LeftContainerWrapper>
      <FoodTrayImg src={FoodTrayIcon} alt="Food Tray" />


        </LeftContainerWrapper>
    )
}

export default LeftContainer  