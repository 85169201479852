import { Line, BrandCardSkeleton } from "./SkeletonLoader.styled";
function BrandCardLoader() {
    return (
      <BrandCardSkeleton>
        <Line width="85" height="25" absolute top="33.3px" left="21.3px" />
        <Line width="254" height="13" absolute top="76px" left="26.6px" />
        <Line width="100" height="13" absolute top="100px" left="26.6px" />
        <Line width="140" height="21" absolute bottom="77px" right="26px" />
        <Line width="102" height="33" absolute bottom="24px" right="26px" />
      </BrandCardSkeleton>
    );
}

export default BrandCardLoader;