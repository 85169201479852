import styled from "styled-components";
import { devices } from "../../constants/devices";
export const MostOrderedContainer = styled.div`
  width: 70%;
  background: rgb(255, 255, 255);
  border-radius: 12px;
  margin-bottom: 16px;
  padding: 22px 21px;
  position: relative;
  display: ${(props) => (props.visibility ? "block" : "none")} @media
    ${devices.tablet} {
    width: 328px;
  }
`;

export const MostOrderedHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    font-family: poppins-normal;
    font-size: 16px;
    letter-spacing: 0px;
    color: #3d3d3d;
  }
  img {
    width: 21px;
    height: 18px;
  }
`;

export const Line = styled.div`
  width: 190px;
  height: 0.5px;
  background: #70707050;
  margin-top: 5.5px;
`;

export const List = styled.ol`
  padding-inline-start: 15px;
  font-family: poppins300;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0px;
  color: #3d3d3d;
`;

export const ListItem = styled.li``;
export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  div {
    font-family: poppins300;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0px;
    color: #3d3d3d;
  }
`;

export const ErrorData = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  font-family: poppins-normal;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0px;
`;
