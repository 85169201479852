import styled, { keyframes } from "styled-components";

const waveLines = keyframes`
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
`;

export const UserInfoSkeleton = styled.div`
 width: 100%;
 height: 71px;
  display: flex;
  position: relative;
  right: 20px;
  align-items: center;
  margin-top: 30px;
  justify-content: flex-end;
  right: 44px;
  
`;

export const BrandCardSkeleton = styled.div`
  width: 315px;
  height: 200px;
  flex-shrink: 0;
  border-radius: 20px;
  position: relative;
  border: 1px solid #bfbaba;

`;

export const LeaderBoardSkeleton = styled.div`
  width: 315px;
  margin: 15px 32px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

export const ChallengeSkeleton = styled.div`
  width: 324px;
  height: 180.449px;
  position: relative;
  
  border: 1px solid #bfbaba;
  border-radius: 20px;
  height: 220px;
  
  
`;

export const BigOrderSkeleton = styled.div`
  width: 250px;
  height: 180px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border: 1px solid #bfbaba;
  padding: 20px;
  border-radius: 20px;
`;

export const BigOrderItemSkeleton = styled.div`
    height: 46px;
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const BigOrderItemData = styled.div`
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 9px;
`;


export const LeaderBoardItem = styled.div`
  height: ${(props) => (props.header ? "40px" : "56px")};
  width: 100%;
  border-radius: ${(props) => (props.header ? "12px" : "0")};
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: ${waveLines} 2s infinite ease-out;
`;




export const Line = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin: ${(props) => (props.margin ? props.margin : "unset")};
  border-radius: 2px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: ${waveLines} 2s infinite ease-out;

  position: ${(props) => (props.absolute ? "absolute" : "unset")};
  top: ${(props) => (props.top ? props.top : "unset")};
  left: ${(props) => (props.left ? props.left : "unset")};
  bottom: ${(props) => (props.bottom ? props.bottom : "unset")};
  right: ${(props) => (props.right ? props.right : "unset")};
`;

export const Square = styled.div`
  height: 46px;
  width: 46px;
  border-radius: 2px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: ${waveLines} 2s infinite ease-out;
`;

export const Circle = styled.div`
  height: ${(props) => (props.height ? props.height : "40")}px;
  width: ${(props) => (props.width ? props.width : "40")}px;
  border-radius: 50%;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: ${waveLines} 2s infinite ease-out;
  position: ${(props) => (props.absolute ? "absolute" : "unset")};
  top: ${(props) => (props.top ? props.top : "unset")};
  left: ${(props) => (props.left ? props.left : "unset")};
  bottom: ${(props) => (props.bottom ? props.bottom : "unset")};
  right: ${(props) => (props.right ? props.right : "unset")};
`;
