import styled from "styled-components";
import { devices } from "../../constants/devices";

export const LeftArrow = styled.div`
display: none;

@media ${devices.tablet} {
  display: block;
  width: 0;
  height: 0;
  border-top: ${(props) => (props.size ? props.size : "22.5px")} solid
    transparent;
  border-bottom: ${(props) => (props.size ? props.size : "22.5px")} solid
    transparent;
  border-right: ${(props) => (props.size ? props.size : "22.5px")} solid
    ${(props) => (props.color ? props.color : "#6a36f0")};
  position: ${(props) => (props.position ? "absolute" : "unset")};
  top: 198px;
}

`;
export const RightArrow = styled.div`
display: none;
@media ${devices.tablet} {
  display: block;
  width: 0;
  height: 0;
  border-top: ${(props) => (props.size ? props.size : "22.5px")} solid
    transparent;
  border-bottom: ${(props) => (props.size ? props.size : "22.5px")} solid
    transparent;
  border-left: ${(props) => (props.size ? props.size : "22.5px")} solid
    ${(props) => (props.color ? props.color : "#6a36f0")};
  position: ${(props) => (props.position ? "absolute" : "unset")};
  top: 198px;
  right: 0px;
  }

`;
export const AmountContainer = styled.div`
  height: 76px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 31px;
`;

export const AmountLabel = styled.div`
  letter-spacing: 0px;
  color: #3d3d3d;
  font-family: poppins300;
  font-size: 18px;
`;

export const AmountText = styled.div`
  width: 216px;
  height: 45px;
  background: #6a36f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: roboto-bold;
  font-size: 28px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;
export const Amount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
