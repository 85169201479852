import styled from "styled-components";

export const SharepageCont = styled.div`
width: 100%;
height: 100%;
/* background-color: green; */
border-radius: 0px 40px 0px 0px;
box-sizing: border-box;

`

export const SharePageBanner = styled.div`
background-image: url(${props => props.bannerBg});
background-size: cover;
background-repeat: no-repeat;
width: 100%;
height: 20%;
background-color: #F9F6FF;
border-radius: 0px 40px 0px 0px;
display: flex;
justify-content: center;
align-items: center;

.bannerDivWrapper{
    box-sizing: border-box;
    width: 33%;
    height: 100%;
    flex-basis: 1;
    border-radius: 0px 40px 0px 0px;
    position: relative;

}
`

export const ProfilePic = styled.div`
    width: 179px;
    height: 179px;
    flex-shrink: 0;
    background-color: ${props => props.bgColor};
    position: absolute;
    top: 84px;
    left: 46%;
    /* transform: translateX(-50%); */
    border-radius: 50%;
    border: 4.5PX solid #fff;
    background-image: url(${props => props.prflPic});
    background-position: center;
    background-size: contain;

    .SharePageDetailInnerDiv{
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 50%;

}
.changePic{
    width: 30px;
height: 30px;
background-color: #FFF;
flex-shrink: 0;
position: absolute;
top: 83%;
left: 70%;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
border-radius: 50%;
}
`

export const RandomImgDiv = styled.div`
width: 50px;
height: 50px;
flex-shrink: 0;
background-color:  ${props => props.bg};
position: absolute;
left: ${props => props.left};
top:  ${props => props.top};
box-sizing: border-box;
border-radius: 50%;
border: 4.5px solid #FFF;
display: flex;
justify-content: center;
align-items: center;
img{
    width: 100%;
}
`
export const SharePageDetailDiv = styled.div`
width: 100%;
height: 80%;
/* background: yellow; */
padding-top: 149px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
`

export const GreetMessage = styled.div`
width: 100%;
/* background-color: red; */
color: #3A3A3A;
leading-trim: both;
text-edge: cap;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
justify-content: center;
`

export const UserShareCard = styled.div`
width: 315px;
height: 56px;
flex-shrink: 0;
background-color: #FFF1D2;
margin-top: 28px;
display: flex;
justify-content: space-between;
align-items: center;
.wrap{
    display: flex;
    align-items: center;
}

.sharePageSl{
    background-image: url(${props => props.modelImg});
    background-position: center;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px; 
    margin-left: 5px;
    background-size: contain;
    padding: 5px;
}

.sharePageImg{
    width: 42.857px;
height: 42px;
flex-shrink: 0;
/* background-color: red; */
display: flex;
justify-content: center;
align-items: center;
margin-left: 10px;

}
.sharePageImg img{
    /* width: 100%; */
    height: 140%;
}
.sharePageName{
    color: #000;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 13px; /* 100% */
margin-left: 10px;
}
.sharePageAmt{
    color: #F26724;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 21px;
margin-right: 10px;
}
`
export const SharePageDec = styled.div`
width: 497px;
height: 43px;
color: #3A3A3A;
text-align: center;
leading-trim: both;
text-edge: cap;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 166.667% */
letter-spacing: 0.54px;
margin-top: 40px;
margin-bottom: 75px;

`
export const SharContainer = styled.div`
width: 100%;
/* background-color: red; */
display: flex;
justify-content: center;
align-items: center; 
.txt{
    color: #3A3A3A;
text-align: center;
leading-trim: both;
text-edge: cap;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 166.667% */
letter-spacing: 0.54px;

}
.shareButtonsDiv{
    display: flex;
}
`
export const ShareIconDiv = styled.div`
width: 40px;
height: 40px;
/* background-color: green; */
margin-left: 25px;
border-radius: 50%;
background-image: url(${props => props.ShareIcon});
background-position: center;
background-repeat: no-repeat;
cursor: pointer;
`

export const PublicShareContainer = styled.a`
width: 653px;
height: 64px;
flex-shrink: 0;
display: flex;
justify-content: center;
align-items: center;
color: #000;
text-align: right;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 21px; /* 116.667% */
border-radius: 50px;
border: 1px solid #999;
cursor: pointer;
text-decoration: none;
`
export const PublicShareContImg = styled.img`
margin-right: 10px;

`