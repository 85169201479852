import {
  Amount,
  AmountLabel,
  AmountText,
  AmountContainer,
  LeftArrow,
  RightArrow,
} from "./TotalAmount.styled";
import Strings from "../../constants/Strings";
import useFooddyStore from "../../store";
import Loader from "./Loader";
function TotalAmount() {
  const { totalAmount } = useFooddyStore();
  const { totalAmountLabel } = Strings;

  return (
    <AmountContainer>
      <AmountLabel>{totalAmountLabel}</AmountLabel>
      <Amount>
        <LeftArrow />
        <AmountText>
          {/* {totalAmount ? totalAmount : <Loader amountLoader={true} />} */}
          {totalAmount}
        </AmountText>
        <RightArrow />
      </Amount>
    </AmountContainer>
  );
}

export default TotalAmount;
