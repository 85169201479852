import styled from "styled-components";

export const TabsHeaderContainer = styled.div`
  width: 100%;
  height: 63px;
  display: grid;
  grid-template-columns: 50% 50%;
`;

export const Tabs = styled.div`
  height: 100%;
  color: #707070;
  font-family: poppins-normal;
  font-size: 16px;
  letter-spacing: 0px;
  color: #70707080;
  border-bottom: 4px solid #3d3d3d10;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
