import styled from "styled-components";
import { devices } from "../../constants/devices";
export const TabContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  flex-direction: column;
`;

export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  gap: 26px;
  @media ${devices.tablet} {
    align-items: start;
    justify-content: center;
    flex-direction: row;
  }
`;

export const ReportCard = styled.div``;

export const CardContainer = styled.div`
  width: 292px;
  height: fit-content;
  background: #f7f4ff;
  border-radius: 11px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${devices.tablet} {
    width: 400px;
  }
`;

export const DomainImage = styled.div`
  width: 143px;
  height: 59px;
  margin-top: 21px;
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: 100%;
  }
`;

export const AnalyzeSpending = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  margin-top: 30px;
  width: 100%;
  height: 142px;
  background: rgba(112, 112, 112, 0.05) 0% 0% no-repeat padding-box;
  border: 1px solid rgba(112, 112, 112, 0.05);
`;

export const AnalyzeLabel = styled.div`
  font-family: poppins-normal;
  font-size: 16px;
  line-height: 25px;
  color: #3d3d3d;
`;

export const AnalyzeButton = styled.div`
  width: 222px;
  height: 42px;
  background: #6a36f0;
  border: 1px solid #6a36f0;
  border-radius: 6px;
  font-family: poppins-normal;
  font-size: 16px;
  line-height: 25px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
