import styled from "styled-components";

export const ShareContainer = styled.div`
  width: 262px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 6px 6px #00000040;
  border: 1px solid #6a36f0;
  border-radius: 11px;
  position: absolute;
  top: 64px;
  right: 0;
  padding: 20px 0;
`;

export const ShareTitle = styled.div`
  font-family: poppins300;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0px;
  color: #6a36f0;
  text-align: center;
`;

export const Button = styled.div`
  /* width: 39px;
  height: 39px; */
  background: #ffffff;
  width: 100%;
  height: 100%;
  border: 0.5px solid #3d3d3d33;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;  
  img{
    width: 100%;
    height: 100%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 11px; 
  gap: 7px;

  .react-share__ShareButton{
    display: flex;
  }
`;