import styled from "styled-components";

export const PubliSharedContainer= styled.div`
  width: 100vw;
  height: 100vh;
  /* overflow-x: hidden; */
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${props=>props.backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const PublicShareInsideCont= styled.div`
width: 1214px;
height: 789px;
border-radius: 40px;
background: #F9F6FF;
background: ${props => props.showShare ? "#fff" : "#F9F6FF"};
display: flex;
position: relative;
`
export const PublicShareLeftContainer = styled.div`
width: 100px;
height: 789px;
flex-shrink: 0;
border-radius: 40px 0px 0px 40px;
display: flex;
justify-content: center;
align-items: flex-start;
background-color: #fff;
/* background-color: red; */
position: relative;

`
export const PublicGoBackDiv= styled.div`
display: flex;
width: 40px;
height: 40px;
padding: 8px 6.999px 7px 8.001px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 400px;
box-sizing: border-box;
margin-top: 57px;
/* background-color: red; */


`
export const PublicGoBackImg = styled.img`
`