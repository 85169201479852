import {
  MostOrderedContainer,
  MostOrderedHeader,
  Line,
  List,
  ListItem,
  Item,
} from "./MostOrderedDishes.styled";
import Strings from "../../constants/Strings";
import dishes from "../../assets/cutlery.svg";
import medal from "../../assets/medal.svg";

import useFooddyStore from "../../store";

function MostOrderedDishes() {
  const { dishesList } = useFooddyStore();

  const getList = () => {
    const list = [];
    dishesList.forEach((dish, index) => {
      list.push(
        <ListItem>
          <Item>
            <div>{dish.itemName}</div>
            {index === 0 ? <img src={medal} alt="medal" /> : null}
          </Item>
        </ListItem>
      );
    });
    return list;
  };

  return (
    <MostOrderedContainer visibility={dishesList.length ? true : false}>
      <MostOrderedHeader>
        <div>{Strings.orderdDishes}</div>
        <img src={dishes} alt="dishes" />
      </MostOrderedHeader>
      <Line />
      <List>{getList()}</List>
    </MostOrderedContainer>
  );
}

export default MostOrderedDishes;
