import { UserInfoSkeleton, Line, Circle } from "./SkeletonLoader.styled";

function UserInfoLoader() {
  return (
    <UserInfoSkeleton>
      <Line height="21" width="129"  margin = "10px"/>
      <Circle height="40" width="40"  />

    </UserInfoSkeleton>
  );
}

export default UserInfoLoader;
